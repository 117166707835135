import ApiService from './ApiService'
class ArticleService extends ApiService {
    ArticleList(filter) {
        return super.Post('/api/Article/List/', filter);
    }
    GetArticle(id) {
            return super.Get('/Api/Article/' + id);
        }
        /**
         * 添加
         * @param data
         * @constructor
         */
    Add(data) {
            let url = '/api/user/addnews';
            return super.Post(url, data)
        }
        /**
         * 修改动态
         * @param data
         * @constructor
         */
    Modify(data) {
        let url = '/api/user/modifynews';
        return super.Post(url, data)
    }

    /**
     * 删除动态
     * @param newsID
     * @constructor
     */
    Delete(newsID) {
        let url = `/api/user/deletenews/${newsID}`
        return super.Post(url, {})
    }

}
export default new ArticleService()