<template>
  <div class="subpage">
    <slot></slot>
  </div>
</template>
<script>
export default {
  created: function() {},
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  methods: {
    nav: function(path) {
      this.$router.push(path);
    }
  }
};
</script>

